import React from 'react'
import {graphql} from 'gatsby'
import {css} from '@emotion/core'
import styled from '@emotion/styled'
import Markdown from 'react-markdown'
import SEO from 'components/seo'
import Layout from 'components/layout'
import Container from 'components/container'
import Hero from 'components/big-hero'
import theme from '../../config/theme'
import {bpMaxMD, bpMaxSM} from '../lib/breakpoints'
import {rhythm, fonts} from '../lib/typography'
import Link from '../components/link'
import 'katex/dist/katex.min.css'

// Enhancing typography for better visual hierarchy
const PostTitle = styled.h3`
  margin-bottom: ${rhythm(0.3)};
  font-size: 26px;
  font-family: ${fonts.semibold};
  color: ${theme.colors.accentDeep}; // Updated color for better visual appeal
  transition: color 0.3s ease;

  &:hover {
    color: ${theme.colors.blue}; // Updated hover color for interactivity
  }
`

const Description = styled.div`
  width: 100%;
  background: ${theme.colors.offWhite};
  p {
    margin-bottom: ${rhythm(0.5)};
    font-size: 16px;
    color: ${theme.colors.darkGray}; // Softer color for readability
  }
`

const ReadMoreLink = styled(Link)`
  font-weight: bold;
  margin-top: ${rhythm(0.25)};
  color: ${theme.colors.accent}; // Eye-catching color for CTAs
  &:hover {
    text-decoration: underline;
    color: ${theme.colors.accentSoftActive}; // Darker teal on hover
  }
`

export default function Index({data: {allMdx}}) {
  return (
    <Layout headerColor={theme.colors.white} logo={false} hero={<Hero />}>
      <SEO title="Blog Posts" description="Browse our latest blog posts." />
      <Container
        css={css`
          position: relative;
          border-radius: 8px;
          padding: 10px 80px;
          margin-bottom: ${rhythm(0.5)};
          //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          ${bpMaxMD} {
            padding: 20px;
          }
          ${bpMaxSM} {
            border-radius: 0;
          }
          h2 {
            margin-bottom: ${rhythm(1)};
            margin-top: ${rhythm(0)};
            color: ${theme.colors.accentDeep}; // Consistent heading color
          }
        `}
      >
        <h2>Blog</h2>
        {allMdx.edges.map(({node: post}) => (
          <div key={post.id}>
            <Link
              to={post.fields.slug}
              aria-label={`View ${post.frontmatter.title}`}
            >
              <PostTitle>{post.frontmatter.title}</PostTitle>
            </Link>
            <Description>
              {post.frontmatter.description && (
                <Markdown source={post.frontmatter.description} />
              )}
              <ReadMoreLink to={post.fields.slug}>Read more →</ReadMoreLink>
            </Description>
          </div>
        ))}
        {!allMdx.edges.length && <PostTitle>No posts available yet.</PostTitle>}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      limit: 5
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {
        frontmatter: {published: {ne: false}, unlisted: {ne: true}}
        fileAbsolutePath: {regex: "//content/blog//"}
      }
    ) {
      edges {
        node {
          id
          fields {
            title
            slug
            date
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            keywords
          }
        }
      }
    }
  }
`
